<template>
   <v-form ref="trade-in-setting-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Trade-in Category</h3>
         </v-col>
         <v-col cols="12">
            <v-btn color="secondary" class="m-auto px-8" large @click.prevent="$router.go(-1)" :disabled="isFetching">Back</v-btn>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.title" label="Category title" :required="true" />
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col cols="12">
            <v-btn color="primary" class="px-8 form-btn" large :disabled="isFetching" type="submit">Save</v-btn>
            <FormDeleteBtn :removeAction="removeItem" v-if="!isNew" />
         </v-col>
      </v-row>
   </v-form>
</template>

<script>
import FormControl from '@/components/form/FormControl'
import FormDeleteBtn from '@/components/form/FormDeleteBtn'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

export default {
   name: 'TradeInCategoryDetail',
   components: {
      FormControl,
      FormDeleteBtn,
   },
   data() {
      return {
         isNew: true,
         data: {
            id: _.toNumber(this.$route.params.id),
            title: '',
            status: 'active',
         },
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),

      async initData() {
         if (this.data.id) {
            const resp = await this.sendRequest({ type: 'get_trade_in_category_by_id', data: { id: this.data.id } })
            if (!resp) return
            this.isNew = false
            this.data = {
               ...this.data,
               title: resp.data.title,
            }
         }
      },
      async formSubmit() {
         console.log('save', this.data.id)
         const isValid = this.$refs['trade-in-setting-form'].validate()
         if (isValid) {
            const action = this.isNew ? this.createItem : this.updateItem
            const resp = await action()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp, 
               returnLink: (this.data.id === undefined ? { name: 'tradeInCategory' } : null )
            });
            this.setShowDialog(true)
            if (!!resp && this.isNew) {
               this.isNew = false
               this.data.id = resp.data.id
               this.initData()
               this.$router.replace({ name: 'tradeInCategoryDetail', params: { id: resp.data.id } })
            }
         }
      },
      async createItem() {
         console.log('create')
         const formdata = {
            status: 'active',
            data_status: 'active',
            title: this.data.title
         }
         return await this.sendRequest({ type: 'add_trade_in_category', data: formdata })
      },
      async updateItem() {
         console.log('update')
         const formdata = {
            data_type: 'trade_in_category_data',
            id: this.data.id,
            title: this.data.title,
         }
         return await this.sendRequest({ type: 'update_trade_in_category', data: formdata })
      },
      async removeItem(dialog) {
         console.log('delete')
         dialog.value = false
         const formdata = {
            data_type: 'trade_in_category_data',
            id: this.data.id,
            status: 'inactive',
         }
         const resp = await this.sendRequest({ type: 'update_trade_in_category', data: formdata })
         if (!resp) {
            this.setDialogMessage({ 
               message: 'Failed', 
               isError: true,
            })
            this.setShowDialog(true)
            return
         }
         this.$router.push({name: 'tradeInCategory'})
      },
   },
   async mounted() {
      await this.initData()
   }
}
</script>
